<template>
  <div class="companies">
    <MobileTable
      v-model:selection="store.state.maintenance.selectedMaintenances"
      :label="$route.name"
      :columns="columns"
      :data="store.state.maintenance.maintenances"
    />
    <DesktopTable
      v-model:selection="store.state.maintenance.selectedMaintenances"
      :label="$route.name"
      :columns="columns"
      :data="store.state.maintenance.maintenances"
    />
    <Actions :items="actions" />
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopTable from "../../widgets/tables/DesktopTable";
import { onBeforeMount, ref } from "vue";
import store from "../../store";
import { openMode } from "../../constants";

export default {
  components: { MobileTable, DesktopTable, Actions },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const columns = [
      {
        field: "description",
        header: "Mantenimiento",
        sort: true,
      },
      {
        field: "location",
        header: "Ubicación",
      },
      {
        field: "date",
        header: "Fecha Inicio",
      },
      {
        field: "time",
        header: "Hora Inicio",
      },
      {
        field: "provider",
        header: "Proveedor",
      },
      {
        field: "status",
        header: "Estado",
      },
      {
        field: "frequency",
        header: "Frecuencia",
      },
    ];

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("setSelectedMaintenances", []);
      const finded = store.state.general.breadcrumbs.find(
        (item) => item.to === route.path
      );
      if (!finded)
        store.commit("addBreadcrumb", { label: route.name, to: route.path });
      await store.dispatch(
        "MAINTENANCE_ALL",
        store.state.general.selectedBuilding
      );
      store.commit("setLoading", false);
    });

    const onCreate = () => {
      store.commit("setOpenMode", openMode.CREATE);
      router.push("/mantenimientos/nuevo");
    };
    // const onModify = () => {
    //   if(store.state.maintenance.selectedMaintenances.length !== 1) {
    //     alert('Debe seleccionar un registro!')
    //   } else {
    //     store.commit('setOpenMode', openMode.MODIFY)
    //     router.push('/mantenimientos/modificar')
    //   }
    // }
    const changeStatus = async (status) => {
      if (!store.state.maintenance.selectedMaintenances.length) {
        alert("Debe seleccionar un registro!");
      } else {
        const maintenances = store.state.maintenance.selectedMaintenances.map(
          (item) => item.id
        );
        store.commit("setLoading", true);
        const response = await store.dispatch("MAINTENANCE_SET_STATUS", {
          maintenances,
          status,
        });
        if (response.ok) {
          store.commit("setSelectedMaintenances", []);
          await store.dispatch(
            "MAINTENANCE_ALL",
            store.state.general.selectedBuilding
          );
          store.commit("setLoading", false);
        } else {
          store.commit("setLoading", false);
        }
      }
    };

    const actions = [
      {
        name: "Nuevo",
        action: onCreate,
        hidden: router
          .getRoutes()
          .find((route) => route.path === "/mantenimientos/nuevo"),
      },
      // {
      //   name: 'Modificar',
      //   action: onModify,
      //   hidden: router.getRoutes().find(route => route.path === '/mantenimientos/modificar')
      // }
      {
        name: "Confirmar",
        action: () => changeStatus(2),
        hidden: true,
      },
      {
        name: "Anular",
        action: () => changeStatus(3),
        hidden: true,
      },
    ];

    return { columns, store, actions };
  },
};
</script>

<style scoped></style>
